import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import StatusLabel from '../molecules/StatusLabel/StatusLabel';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import moment from 'moment';


export const STATUS_ICON = {
    PENDING: <EditOutlinedIcon
            sx={{
            background: '#FFF',
            color: 'neutral.dark80',
            }}
        />,
    PAYMENT_PROCESSED:<DoneOutlinedIcon
      sx={{
        background: '#FFF',
        color: 'primary.main',
      }}
    />,    
    APPROVED: <DoneOutlinedIcon
    sx={{
      background: '#FFF',
      color: 'primary.main',
    }}
  />,
    DRAFT: 'DRAFT',
    RE_SUBMITTED: 'RE-SUBMITTED',
    RE_SUBMIT: 'SENT BACK',
    REJECTED: <ClearOutlinedIcon
    sx={{
      background: '#FFF',
      color: 'accentSecondary.main',
    }}
  />,
    STATUS: <PendingIcon
    sx={{
      background: '#FFF',
      color: 'neutral.dark80',
    }}
  />,
    DELETED: <ClearOutlinedIcon
    sx={{
      background: '#FFF',
      color: 'accentSecondary.main',
    }}
  />,
};

export const CLAIM_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    DRAFT: 'DRAFT',
    RE_SUBMITTED: 'RE-SUBMITTED',
    RE_SUBMIT: 'SENT BACK',
    REJECTED: 'REJECTED',
    DELETED: 'DELETED',
    PAYMENT_PROCESSED: 'Payment Processed',
    SENT_TO_PAYROLL:'SENT TO PAYROLL',
    REVERSE:'REVERSED'
};

export default function ClaimAuditLog({claimDetails}) {
    const {status, nextApproval, auditTrail, approvals, currentLevel} = claimDetails;
    const [allLogs, setAllLogs] = useState([]);
    const setLogsTrail = ()=>{
      try {
        console.log('logs called!');
         if( status === CLAIM_STATUS.PENDING || status === CLAIM_STATUS.DRAFT || status === CLAIM_STATUS.RE_SUBMIT || status === CLAIM_STATUS.RE_SUBMITTED || status === CLAIM_STATUS.REJECTED ){
            let logs = [...auditTrail];
            approvals?.steps.forEach((approver, i) => {
                if(approver.stepNumber >= currentLevel){
                    logs.push({designation: approver.name, status: CLAIM_STATUS.PENDING});
                }
            });
            setAllLogs(logs);
        } else if(status){
          const sorted = auditTrail.sort((a,b)=> a.id - b.id);
          console.log('sorted', sorted);
            setAllLogs(()=>sorted.map((log,i)=>{
              if(i === 0){
                log.status = status;
              }
              return log;
            }));
        }
      } catch (error) {
        console.log('Error', error);
      }
    };
    
    useEffect(() => {
      setLogsTrail();
    }, []);
    

  return (
    <>
    {allLogs.map((log,i) =>(
    <Box key={i} maxWidth={360} mb={2}>
      <Paper>
        <Box p={1} position='relative'>
          <StatusLabel value={log.status} type={String(log.status).toLowerCase()} />
          <Paper
            sx={{
              position: 'absolute',
              left: -68,
              top: 0,
              width: 40,
              height: 40,
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {STATUS_ICON[log.status]}
          </Paper>
          <Typography variant='body2' color={'neutral.dark100'} mt={1} mb={0}>
            <b>Name:</b>{log.createdBy ? `${log.createdBy?.firstName} ${log.createdBy?.lastName}` : log.designation}
          </Typography>
          <Typography variant='body3' color={'neutral.dark70'} mt={1} mb={0}>
          <b>Comment:</b>{log.comment || ''}
          </Typography>
          {log.createdAt && (
          <>
          <Paper sx={{ my: 1 }}>
            {log.remark && (
                <Box py={2} px={1}>
                <Typography variant='caption' color={'neutral.dark80'}>
                  <b>remark:</b>{log.remark}
                </Typography>
              </Box>
            )}
          </Paper>
          <Typography variant='body3' color={'neutral.dark60'} mb={0}>
            <b>Date:</b> {moment(log.createdAt).format('DD-MM-YYYY hh:mm a')}
          </Typography>
          </>
          )}
        </Box>
      </Paper>
    </Box>
    ))}
  </>
  );
}
